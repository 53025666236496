/*
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
*/
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
//import { render } from 'react-dom';
import Gallery from 'react-grid-gallery';
//import 'url-polyfill';

import * as serviceWorker from './serviceWorker';


class App0 extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            images: this.props.images,
            currentImage: 0
        };

        this.onCurrentImageChange = this.onCurrentImageChange.bind(this);
        //this.deleteImage = this.deleteImage.bind(this);
    }

    onCurrentImageChange(index) {
        this.setState({ currentImage: index });
    }

/*
    deleteImage() {
        if (window.confirm(`Are you sure you want to delete image number ${this.state.currentImage}?`)) {
            var images = this.state.images.slice();
            images.splice(this.state.currentImage, 1)
            this.setState({
                images: images
            });
        }
    }
*/

/*
    render () {
        return (
            <div style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                border: "1px solid #ddd",
                overflow: "auto"}}>
                <div style={{
                     padding: "2px",
                     color: "#666"
                    }}>Current image: {this.state.currentImage}</div>
                <Gallery
                    images={this.state.images}
                    enableLightbox={true}
                    enableImageSelection={false}
                    currentImageWillChange={this.onCurrentImageChange}

                    customControls={[
                        <button key="deleteImage" onClick={this.deleteImage}>Delete Image</button>
                    ]}
                />
            </div>
        );
    }
*/
    render () {
        return (
            <div style={{
                display: "block",
                minHeight: "1px",
                width: "100%",
                border: "1px solid #ddd",
                overflow: "auto"}}>
                <Gallery
                    images={this.state.images}
                    enableLightbox={true}
                    enableImageSelection={false}
                    currentImageWillChange={this.onCurrentImageChange}
                />
            </div>
        );
    }
}

App0.propTypes = {
    images: PropTypes.arrayOf(
        PropTypes.shape({
            src: PropTypes.string.isRequired,
            thumbnail: PropTypes.string.isRequired,
            srcset: PropTypes.array,
            caption: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.element
            ]),
            thumbnailWidth: PropTypes.number.isRequired,
            thumbnailHeight: PropTypes.number.isRequired
        })
    ).isRequired
};

App0.defaultProps = {
    images: [
    {
            src: "/img/apple4252.jpg",
            thumbnail: "/img/apple4252.jpg",
            thumbnailHeight: 320,
            caption: "새벽녘 사과밭"
     },
    {
            src: "/img/apple4188.jpg",
            thumbnail: "/img/apple4188.jpg",
            thumbnailHeight: 320,
            caption: "얼음골"
     },
     {
            src: "/img/apple4224.jpg",
            thumbnail: "/img/apple4224.jpg",
            thumbnailHeight: 320,
            caption: "얼음골"
     },
     {
            src: "/img/apple4241.jpg",
            thumbnail: "/img/apple4241.jpg",
            thumbnailHeight: 320,
            caption: "얼음골"
      },
      {
            src: "/img/apple11-21-52_347.jpg",
            thumbnail: "/img/apple11-21-52_347.jpg",
            thumbnailHeight: 320,
            caption: "머루",
/*
            thumbnailCaption: "8H",
            tags: [{value: "Nature", title: "Nature | Flowers"}]
*/
        },
/*
      {
            src: "/img/apple09-13_11-23-40_422.jpg",
            thumbnail: "/img/apple09-13_11-23-40_422.jpg",
            thumbnailHeight: 320,
            caption: "달팽이"
        },
*/
       {
            src: "/img/apple09-13_11-24-11_140.jpg",
            thumbnail: "/img/apple09-13_11-24-11_140.jpg",
            thumbnailHeight: 320,
            caption: "달팽이"
        },
       {
            src: "/img/apple2807.jpg",
            thumbnail: "/img/apple2807.jpg",
            thumbnailHeight: 320,
            caption: "참~"
        },
/*
       {
            src: "/img/apple9911.jpg",
            thumbnail: "/img/apple9911.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
*/
       {
            src: "/img/apple9817.jpg",
            thumbnail: "/img/apple9817.jpg",
            thumbnailHeight: 320,
            caption: "도라지꽃, 나비, 그리고 소나무 "
        },
       {
            src: "/img/apple9220.jpg",
            thumbnail: "/img/apple9220.jpg",
            thumbnailHeight: 320,
            caption: "잠자리"
        },
       {
            src: "/img/apple1799.jpg",
            thumbnail: "/img/apple1799.jpg",
            thumbnailHeight: 320,
            caption: "한 여름의 사과"
        },
       {
            src: "/img/apple1819.jpg",
            thumbnail: "/img/apple1819.jpg",
            thumbnailHeight: 320,
            caption: "한 여름의 사과"
        },
/*
        {
            src: "/img/apple1793.jpg",
            thumbnail: "/img/apple1793.jpg",
            thumbnailHeight: 320,
            caption: "땅콩"
        },
*/
        {
            src: "/img/apple1786.jpg",
            thumbnail: "/img/apple1786.jpg",
            thumbnailHeight: 320,
            caption: "한 여름의 사과"
        },
        {
            src: "/img/apple1767.jpg",
            thumbnail: "/img/apple1767.jpg",
            thumbnailHeight: 320,
            caption: "한 여름의 사과"
        },
/*
        {
            src: "/img/apple1755.jpg",
            thumbnail: "/img/apple1755.jpg",
            thumbnailHeight: 320,
            caption: "한 여름의 사과"
        },
*/
        {
            src: "/img/apple1747.jpg",
            thumbnail: "/img/apple1747.jpg",
            thumbnailHeight: 320,
            caption: "사과"
        },
/*
        {
            src: "/img/apple1669.jpg",
            thumbnail: "/img/apple1669.jpg",
            thumbnailHeight: 320,
            caption: "고추밭"
        },
        {
            src: "/img/apple1660.jpg",
            thumbnail: "/img/apple1660.jpg",
            thumbnailHeight: 320,
            caption: "고추밭"
        },
        {
            src: "/img/apple1656.jpg",
            thumbnail: "/img/apple1656.jpg",
            thumbnailHeight: 320,
            caption: "고추밭"
        },
*/
/*
        {
            src: "/img/apple1627.jpg",
            thumbnail: "/img/apple1627.jpg",
            thumbnailHeight: 320,
            caption: "어린 사과나무"
        },
*/
/*
        {
            src: "/img/apple1621.jpg",
            thumbnail: "/img/apple1621.jpg",
            thumbnailHeight: 320,
            caption: "사과밭 사이 고추밭"
        },
*/
/*
        {
            src: "/img/apple1547.jpg",
            thumbnail: "/img/apple1547.jpg",
            thumbnailHeight: 320,
            caption: "참~"
        },
*/
        {
            src: "/img/apple0204.jpg",
            thumbnail: "/img/apple0204.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
        {
            src: "/img/apple0183.jpg",
            thumbnail: "/img/apple0183.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
       {
            src: "/img/apple0182.jpg",
            thumbnail: "/img/apple0182.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
        {
            src: "/img/apple0168.jpg",
            thumbnail: "/img/apple0168.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
       {
            src: "/img/apple0148.jpg",
            thumbnail: "/img/apple0148.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
        {
            src: "/img/apple0140.jpg",
            thumbnail: "/img/apple0140.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
        {
            src: "/img/apple0113.jpg",
            thumbnail: "/img/apple0113.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
        {
            src: "/img/apple0020.jpg",
            thumbnail: "/img/apple0020.jpg",
            thumbnailHeight: 320,
            caption: "절골계곡"
        },
/*
        {
            src: "/img/apple9899.jpg",
            thumbnail: "/img/apple9899.jpg",
            thumbnailHeight: 320,
            caption: "오미자"
        },
*/
        {
            src: "/img/apple8623.jpg",
            thumbnail: "/img/apple8623.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple8504.jpg",
            thumbnail: "/img/apple8504.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
       {
            src: "/img/apple8232.jpg",
            thumbnail: "/img/apple8232.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple8184.jpg",
            thumbnail: "/img/apple8184.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple8004-g.jpg",
            thumbnail: "/img/apple8004-g.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
       {
            src: "/img/apple6870.jpg",
            thumbnail: "/img/apple6870.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple6829.jpg",
            thumbnail: "/img/apple6829.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple4059.jpg",
            thumbnail: "/img/apple4059.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
/*
        {
            src: "/img/apple9299.jpg",
            thumbnail: "/img/apple9299.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
*/
/*
        {
            src: "/img/apple7339.jpg",
            thumbnail: "/img/apple7339.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
*/
/*
        {
            src: "/img/apple5459_1.jpg",
            thumbnail: "/img/apple5459_1.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
*/
/*
         {
            src: "/img/apple3967.jpg",
            thumbnail: "/img/apple3967.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
*/
/*
        {
            src: "/img/apple1857.jpg",
            thumbnail: "/img/apple1857.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
*/
/*
        {
            src: "/img/apple1740.jpg",
            thumbnail: "/img/apple1740.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
*/
       {
            src: "/img/apple9243.jpg",
            thumbnail: "/img/apple9243.jpg",
            thumbnailHeight: 320,
            caption: "예전 학교 운동장"
        },
        {
            src: "/img/apple9287.jpg",
            thumbnail: "/img/apple9287.jpg",
            thumbnailHeight: 320,
            caption: "재에서 본 마을"
        },
 
        {
            src: "/img/apple9210.jpg",
            thumbnail: "/img/apple9210.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
 
/*
        {
            src: "/img/applen0072.jpg",
            thumbnail: "/img/applen0072.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
*/
 
        {
            src: "/img/apple3416.jpg",
            thumbnail: "/img/apple3416.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple3222.jpg",
            thumbnail: "/img/apple3222.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple3214.jpg",
            thumbnail: "/img/apple3214.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
         {
            src: "/img/apple3043.jpg",
            thumbnail: "/img/apple3043.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple2446.jpg",
            thumbnail: "/img/apple2446.jpg",
            thumbnailHeight: 320,
            caption: "화장저수지"
        },
        {
            src: "/img/apple1701.jpg",
            thumbnail: "/img/apple1701.jpg",
            thumbnailHeight: 320,
            caption: "들깨"
        },
        {
            src: "/img/apple625960.jpg",
            thumbnail: "/img/apple625960.jpg",
            //thumbnailWidth: 257,
            thumbnailHeight: 320,
            caption: ""
        },

        {
            src: "/img/apple535762.jpg",
            thumbnail: "/img/apple535762.jpg",
            //thumbnailWidth: 257,
            thumbnailHeight: 320,
            caption: ""
        },

        {
            src: "/img/apple2280.jpg",
            thumbnail: "/img/apple2280.jpg",
            //thumbnailWidth: ,
            thumbnailHeight: 320,
            caption: "동네 감나무"
        },
        {
            src: "/img/apple2629.jpg",
            thumbnail: "/img/apple2629.jpg",
            //thumbnailWidth: 257,
            thumbnailHeight: 320,
            caption: "참!"
        },
/*
        {
            src: "/img/apple4247-55.jpg",
            thumbnail: "/img/apple4247-55.jpg",
            //thumbnailWidth: 257,
            thumbnailHeight: 320,
            caption: ""
        },
*/
        {
            src: "/img/apple4649.jpg",
            thumbnail: "/img/apple4649.jpg",
            //thumbnailWidth: 257,
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple5349.jpg",
            thumbnail: "/img/apple5349.jpg",
            //thumbnailWidth: 257,
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple6313.jpg",
            thumbnail: "/img/apple6313.jpg",
            //thumbnailWidth: 257,
            thumbnailHeight: 320,
            caption: ""
        },
        {
            src: "/img/apple0640.jpg",
            thumbnail: "/img/apple0640.jpg",
            thumbnailHeight: 320,
            caption: "밭 너머 개울에서"
        },
        {
            src: "/img/apple1020165.jpg",
            thumbnail: "/img/apple1020165.jpg",
            thumbnailHeight: 320,
            caption: ""
        },
    ]
};

ReactDOM.render(<App0 />, document.getElementById('app0'));

/*
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
*/

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
